.gallery-item {
    transition: all .5s ease;
    margin:0px;
    padding:0px;
    img {
      width:400px;
    }
    
    @media only screen and (max-width:824px) {
      img {
        width:80vw;
      }
  }
}




