.header {

    position: relative;
    height:80px;

    width:100%;

  }

#logo {
    display: inline-block;
    min-width: 155px;
    width: 17rem; // 441 //482

    height: 14rem;
    background:url(../images/Jamaal-Nelson.png) no-repeat scroll transparent;
    text-indent: -5000px;
    cursor: pointer;

}


.nav {
    display: flex;
    background: rgba(0, 0, 0, 0);
    color:#EDF2F4;
    position:fixed;
    width: 100%;


    z-index: 200;

    &__main {
        display: flex;
        max-width:1240px;
        justify-content: space-between;
        width:100%;

    @media only screen and (max-width:1261px) {
        max-width:820px;
        width:100%;
      }
  
      @media only screen and (max-width:824px) {
         width:80vw;
        }
    }

    &__space {

        flex:2 1 auto;
        background-color: black;
    }

    &__span {
 
        display:flex;
        width: 100%;
        height: 14rem;
        background-color: #000;
        align-items:flex-end;
 
    }

    &__header {
        // font-size: 2rem;
        padding:1rem 1rem 4.1px;
        cursor: pointer;
        &:hover {
        opacity:.8;
       }
    }
}


