.gallery {

   //  max-width:1260px;
    max-width:1240px;
    
   //  border:1px solid black;

    margin:10rem auto ;
    display:grid;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;   
    row-gap:9.1px;

    grid-auto-rows: 1px;
    transition: all .3s ease-in;
    justify-content: space-around;


    @media only screen and (max-width:1261px) {
      max-width:820px;
    }

    @media only screen and (max-width:824px) {
      display:flex;
      flex-direction: column;
      width:80vw;
      grid-template-columns: 1fr;
      justify-content: space-evenly;
  }

}   


.gallery__detail {
   width:100vw;
   height: 100vh;
   position: fixed;
   top:0;
   left:0;
   padding:0;
   padding-top:20vmin;
   //margin:0 auto; 
   display: flex;
   align-items: center;
   justify-content: center;
   //change z-index to 2 tomove it under header
    z-index:400;

   background: rgba(25,25,25,0.8);
   box-shadow: 0 0 30 #000; 

  // width: 100%;

   
}

.gallery__image {
   position: absolute;
   top:50%;
   left:50%;
   transform:translate(-50%, -50%);


   // height: calc(90vh);
   // widows: 90%;
   height: auto;
   // padding-top:15vh;
   max-height: 100vh;
   max-width: 100vw;
   //width:90%;
   //
   // padding-left:15vh;
   // padding-right:15vh;



}


.zoomIn {
   animation:zoom-in .5s;
}

.zoomOut {
   animation:zoom-out .5s;
}


/* Zoom In */
@keyframes zoom-in {
   0% {
       transform: scale(0) ;
        opacity:0
   }
   100% {
       transform: scale(1) ;
       opacity: 1
   }
}
/* Zoom Out */
@keyframes zoom-out {
   0% {
       transform: scale(1) ;
        opacity:1
   }
   100% {
       transform: scale(0) ;
       opacity: 0
   }
}

