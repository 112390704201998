
// $primary-bgcolor: #EDF2F4;
// $primary-bgcolor2:#F1F1F1;
// $primary_text:#262834;
// $primary_header:#282c34;
// $secondary_text:#EDF2F4;
// $tertiary_color: #8D99AE;


// // size
// $m-size:1.9rem;//1.6rem;
// $m2-size:1.6rem;
// $m3-size:2.1rem;
// $s-size:1rem;
// $l-size:3.8rem; // 3.2
// $l1-size:3.4rem; 
// $xl-size:6.1rem;//4.8rem;

// // Font Sizes 
// $title-size:5rem;
// $title2-size:3.4rem;
// $title3-size:3rem;
// $sub-size:2.8rem;
// $sub2-size:2.1rem;

// // Font Weigth 
// $bold:400;
// $light:100;

// // Line Height
// $normal:1.6;
// $low:1.4;

// // $desktop-breakpoint : 45rem;
// $bp-largest:75em; //1200px
// $bp-large:68.75em; //1100px
// $bp-medium:56.25em;// 900px
// $bp-small:768px;//37.5em; // 600px;
// $bp-xsmall:31.25em; // 600px;


*,
*::after,
*::before {
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}


html {
  font-size: 62.5%;
  overflow-x: hidden;
  // @media only screen and (max-width: $bp-large) {
  //   font-size: 60%;
  // }
  // @media only screen and (max-width: $bp-small) {
  //   font-size: 50%;
  // }
  scroll-behavior: smooth;
  background-color: white;
}

body {
  text-align: center;
  overflow: auto;
}

body {
  margin: 0;

}
